<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form>
        <md-card>
          <md-card-header
              class="md-card-header-icon"
              :class="getClass(headerColor)"
          >
            <div class="card-icon">
              <template v-if="oper==='add'">
                <md-icon>post_add</md-icon>
              </template>
              <template v-else>
                <md-icon>mode_edit</md-icon>
              </template>

            </div>
            <h4 class="title">
              {{ $t(`subnetwork.${oper}`) }}
              <small></small>
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-size-50">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>
                      {{ $t(`subnetwork.name`) }}
                    </label>
                    <md-input
                        v-model="subnetwork.subnetwork_name"
                        :type="'subnetwork_name'"
                        :data-vv-name="'subnetwork_name'"
                        @input="onFieldChange('subnetwork_name')"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`subnetwork.roads`) }}
                    </label>
                    <md-select
                        v-model="subnetwork.subnetwork_items"
                        @input="onFieldChange('items')"
                        multiple
                    >
                      <md-option v-for="(code, index) in roadcodeList" :key="`${code}+${index}`" :value="code">{{ code }}
                      </md-option>
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>
                      {{ $t(`subnetwork.comment`) }}
                    </label>
                    <md-input
                        v-model="subnetwork.comment"
                        @input="onFieldChange('subnetwork_name')"
                    ></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout-item md-size-100 text-right">
                <md-button
                    class="md-success"
                    native-type="submit"
                    @click.native.prevent="validate"
                    :disabled="nothingSave"
                >
                  {{ $t('button.save') }}
                </md-button>
                <md-button class="md-accent" @click.stop.prevent="onCancel">
                  {{ $t('button.close') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>

//import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'subnetwork-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    //SlideYDownTransition
  },
  async created() {
    this.roadcodeList = await this.$store.dispatch("LOAD_ROADCODES_LIST")

    if (this.oper === "upd") {
      this.subnetwork = await this.$store.dispatch("LOAD_SUBNETWORK_BY_ID", this.net_id)
    }
  },
  data() {
    return {
      nothingSave: true,
      subnetwork: {
        subnetwork_name: '',
        subnetwork_items: [],
        comment: ''
      },
      roadcodeList: [],
      net_id: this.$route.params.net_id
    }
  },
  methods: {
    onFieldChange() {
      this.nothingSave = false
    },
    onCancel() {
      this.$router.go(-1)
    },
    getClass: function () {
      //return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      const alert = {
        type: 'success',
        text: '',
        footer: ''
      }

      const {subnetwork_name, subnetwork_items, comment} = this.subnetwork
      try {
        if (this.oper === "add") {
          await this.$store.dispatch('ADD_SUBNETWORK', {
            data: {subnetwork_name, subnetwork_items, comment}
          })
          alert.text= this.$t(`subnetwork.record_was_added`)
        } else if (this.oper === "upd") {
          await this.$store.dispatch('UPD_SUBNETWORK', {
            net_id: this.net_id,
            data: {subnetwork_name, subnetwork_items, comment}
          })
          alert.text= this.$t(`subnetwork.record_was_updated`)
        } else {
          console.log("Unknown operation")
        }

        this.nothingSave = true
        Swal.fire(alert).then(() => {
          //this.touched.category = false
          this.$nextTick(() => this.$validator.reset())
        })
      } catch (err) {
        console.log(err)
        alert.type = 'error'
        if (this.oper === "add") {
          alert.text = this.$t(`plan.record_was_not_added`)
        } else if (this.oper === "upd") {
          alert.text = this.$t(`plan.record_was_not_updated`)
        }
        //alert.footer = this.$t(err)
        Swal.fire(alert)
      }
    }
  },
  computed: {
    isNewSubnetwork() {
      return this.oper === "add"
    }

  }
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}
</style>
